.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  & {
    scrollbar-width: thin;
    scrollbar-color: var(--accent-color) var(--background-color);
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: var(--background-color);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--accent-color);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--default-color);
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.form-group {
  position: relative;
}

.form-control {
  height: 48px;
  background: #fff;
  color: #000;
  font-size: 14px;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #00000038;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.2) !important;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.2) !important;
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.2) !important;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.2) !important;
}

.form-control:focus,
.form-control:active {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid var(--accent-color);
}

.emailInputField {
  position: relative;
  z-index: 0;
  margin-bottom: 20px !important;
}

.emailInputField span {
  position: absolute;
  top: 50%;
  right: 11px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.hero .hero-input {
  height: 60px !important;
}

.features .learn-more-btn {
  color: var(--accent-color);
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0px;
  transition: all 0.3s ease-in-out;
}

.features .learn-more-btn:hover {
  filter: brightness(140%);
}

.features .learn-more-btn span {
  transition: all 0.3s ease-in-out;
}

.features .learn-more-btn:hover span {
  transform: translate(5px, 0);
}

.features .blog-heads {
  font-size: 3rem;
  font-weight: 800;
}

.clients .swiper-pagination {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: color-mix(in srgb, var(--default-color), transparent 85%);
  opacity: 1;
  border: none;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

.clients .testimonial-item {
  padding: 30px 30px 0 30px;
  position: relative;
  background: color-mix(in srgb, var(--default-color), transparent 97%);
  margin-bottom: 50px;
}

.swiper-button-next,
.swiper-button-prev {
  color: #007bff; /* Customize arrow color */
  width: 27px;
  height: 44px;
  border: none !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: auto !important;
  right: var(--swiper-navigation-sides-offset, 21%) !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 16%) !important;
  left: auto;
}

.client-logo-bg {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navmenu a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--nav-color);
  transform-origin: center;
  transition: transform 0.3s ease-out;
}

.navmenu a:hover:after {
  transform: scaleX(1);
  transform-origin: center;
}

.toc {
  margin-top: 1rem;
}

.toc ul {
  list-style-type: none;
  padding: 0;
}
.toc li {
  margin: 5px 0;
}
.toc a {
  text-decoration: none;
  color: #000;
}
.toc a:hover {
  text-decoration: underline;
}

.text-pop-up-top {
  -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: text-pop-up-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc,
      0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc,
      0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc,
      0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc,
      0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@media screen and (max-width: 768px) {
  .features .blog-heads {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 978px) {
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: auto !important;
    right: var(--swiper-navigation-sides-offset, 26%) !important;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 16%) !important;
    left: auto;
  }
}

.contact .php-email-form button[type="button"] {
  background: var(--accent-color);
  color: var(--contrast-color);
  border: 0;
  padding: 10px 30px;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="button"]:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

